import { createInjectionState, truthy } from '@lasso/shared/utils'
import { RefOrGetter } from '@lasso/shared/types'
import { Ref, computed, ref, toRef, watch } from 'vue'
import { UseFormAddonsContext, useApi } from '@lasso/shared/hooks'
import {
  ChannelType,
  CpaGoalType,
  CpaMode,
  OptimizationGoalType, useActivationApi,
} from '@lasso/api-activation/activation'

import { endemicChannels } from '../../../shared/channels'

import { getMediaBidDefaultValue } from '../../../shared'

import {
  BiddingAndOptimizationsFormInput,
  CardBiddingAndOptimizationsFormInput,
  OptimizationAudience,
} from './types'

export const [useProvideCardBiddingAndOptimizations, useCardBiddingAndOptimizations] = createInjectionState(({
  isCardEnabled,
  adGroupId,
  isInitiallyDraft,
  channelId,
  endemicBlendEnabled = ref(false),
  isSourceAdGroup,
  useFieldModelsStartingWith,
  resetField,
  getId,
  validateField,
  setInitialValue,
}: {
  isCardEnabled: RefOrGetter<boolean>
  adGroupId: Readonly<Ref<number>>
  channelId: Readonly<Ref<ChannelType>>
  isInitiallyDraft: boolean
  endemicBlendEnabled?: Readonly<Ref<boolean>>
  isSourceAdGroup: Readonly<Ref<boolean>>
} & UseFormAddonsContext<CardBiddingAndOptimizationsFormInput>) => {
  const {
    mediaBid,
    viewability,
    crossDeviceExtension,
    tapAdCrossDeviceExtension,
    optimizationType,
    optimizationGoal,
    conversionType,
    strategy,
    maintainCpc,
    conversions,
    conversionSegments,
    optimizationSegments,
  } = useFieldModelsStartingWith('biddingAndOptimizations', [
    'mediaBid',
    'viewability',
    'crossDeviceExtension',
    'tapAdCrossDeviceExtension',
    'optimizationType',
    'optimizationGoal',
    'conversionType',
    'strategy',
    'maintainCpc',
    'conversions',
    'conversionSegments',
    'optimizationSegments',
  ])

  const {
    advancedOptimization,
    uniqueReachOptimization,
  } = useFieldModelsStartingWith('optimizationFlags', [
    'advancedOptimization',
    'uniqueReachOptimization',
  ])

  const isEndemicChannel = computed(() => endemicChannels.includes(channelId.value))

  const setInitialBiddingAndOptimizations = (biddingAndOptimizations: BiddingAndOptimizationsFormInput) => {
    resetField('biddingAndOptimizations', { value: biddingAndOptimizations })
  }

  const { loading, onData, error, request } = useApi(
    useActivationApi().getAdGroupMediaBidStrategy,
    () => [{ adGroupId: adGroupId.value }],
    { refetch: true, enabled: toRef(isCardEnabled) },
  )

  onData((data = {}) => {
    const mediaBidValue = isInitiallyDraft ? mediaBid.value : data.mediaBid ?? null
    const conversionValue = (data?.conversion ?? []) as OptimizationAudience[]

    setInitialBiddingAndOptimizations({
      mediaBid: mediaBidValue,
      viewability: data.viewability ?? null,
      crossDeviceExtension: data.crossDeviceExtension ?? false,
      tapAdCrossDeviceExtension: data.tapAdCrossDeviceExtension ?? false,
      optimizationType: data.optimizationGoalType ?? null,
      optimizationGoal: data.optimizationGoal ?? null,
      conversionType: data.cpaGoalTypeId ?? CpaGoalType.POST_VIEW_AND_POST_CLICK,
      strategy: data.cpaModeId ?? CpaMode.PROSPECTING,
      maintainCpc: data.maintainCpc ?? null,
      conversions: conversionValue,
      conversionSegments: data.conversionSegmentIds?.map(id => conversionValue.find(it => id === it.id)).filter(truthy) ?? [],
      optimizationSegments: data.optimizationSegmentIds?.map(id => conversionValue.find(it => id === it.id)).filter(truthy) ?? [],
    })
  })

  const isCtrOptimizationEnabled = computed(() => optimizationType.value === OptimizationGoalType.CTR)
  const isCpaOptimizationEnabled = computed(() => optimizationType.value === OptimizationGoalType.CPA)
  const isCpaAndPostClickOnlyEnabled = computed(() => isCpaOptimizationEnabled.value && conversionType.value === CpaGoalType.POST_CLICK_ONLY)
  const isCpaAndPostViewAndPostClickEnabled = computed(() => isCpaOptimizationEnabled.value && conversionType.value === CpaGoalType.POST_VIEW_AND_POST_CLICK)

  const handleConversionSegmentSelection = (segment: OptimizationAudience) => {
    if (conversionSegments.value.some(({ id }) => id === segment.id)) {
      conversionSegments.value = conversionSegments.value.filter(it => it.id !== segment.id)
    }
    else {
      conversionSegments.value = [...conversionSegments.value, segment]
    }
  }

  const handleOptimizationSegmentSelection = (segment: OptimizationAudience) => {
    if (optimizationSegments.value.some(({ id }) => id === segment.id)) {
      optimizationSegments.value = optimizationSegments.value.filter(it => it.id !== segment.id)
    }
    else {
      optimizationSegments.value = [...optimizationSegments.value, segment]
      // add same to conversion segments if it is not there
      if (!conversionSegments.value.some(({ id }) => id === segment.id)) {
        conversionSegments.value = [...conversionSegments.value, segment]
      }
    }
  }

  const handleConversionSegmentRemove = (segment: OptimizationAudience) => {
    conversionSegments.value = conversionSegments.value.filter(it => it.id !== segment.id)
  }

  const handleOptimizationSegmentRemove = (segment: OptimizationAudience) => {
    optimizationSegments.value = optimizationSegments.value.filter(it => it.id !== segment.id)
  }

  watch(channelId, () => {
    setInitialValue('biddingAndOptimizations.mediaBid', getMediaBidDefaultValue(channelId.value))
  })

  watch(
    [optimizationType, conversionType, strategy],
    () => {
      validateField('biddingAndOptimizations')
    },
  )

  watch(endemicBlendEnabled, () => {
    if (endemicBlendEnabled.value) {
      advancedOptimization.value = false
      uniqueReachOptimization.value = false
    }
  })

  return {
    channelId,
    isSourceAdGroup,
    isEndemicChannel,

    loading,
    error,
    request,

    uniqueReachOptimization,
    advancedOptimization,
    endemicBlendEnabled,

    mediaBid,
    viewability,
    crossDeviceExtension,
    tapAdCrossDeviceExtension,
    optimizationType,
    optimizationGoal,
    conversionType,
    strategy,
    maintainCpc,
    conversions,
    conversionSegments,
    optimizationSegments,

    isCtrOptimizationEnabled,
    isCpaOptimizationEnabled,
    isCpaAndPostClickOnlyEnabled,
    isCpaAndPostViewAndPostClickEnabled,

    handleConversionSegmentSelection,
    handleOptimizationSegmentSelection,
    handleConversionSegmentRemove,
    handleOptimizationSegmentRemove,

    getId,
  }
})
